<template>
  <v-form v-model="valid">
    <v-alert color="grey lighten-3">{{ $t("FORMS.SUBLETTING_APPLICATION.MESSAGE") }}</v-alert>

    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="localFormData.FIRST_NAME"
          :rules="[rules.required]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.FIRST_NAME') + ' *'"
          hide-details
          solo
          :append-icon="errors.first_name ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, first_name: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.LAST_NAME"
          :rules="[rules.required]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.LAST_NAME') + ' *'"
          hide-details
          solo
          :append-icon="errors.last_name ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, last_name: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.STREET"
          :rules="[rules.required]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.STREET') + ' *'"
          hide-details
          solo
          :append-icon="errors.street ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, street: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.ZIP"
          :rules="[rules.required]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.ZIP') + ' *'"
          hide-details
          solo
          :append-icon="errors.zip ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, zip: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.CITY"
          :rules="[rules.required]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.CITY') + ' *'"
          hide-details
          solo
          :append-icon="errors.city ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, city: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.BIRTH_DATE"
          v-mask="'##.##.####'"
          hint="DD.MM.YYYY"
          :rules="[rules.birthdate]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.BIRTH_DATE')"
          hide-details
          solo
          :append-icon="errors.birthdate ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, birthdate: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.NATIONALITY"
          :rules="[rules.required]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.NATIONALITY') + ' *'"
          hide-details
          solo
          :append-icon="errors.nationality ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, nationality: $event }"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.PROFESSION"
          :label="$t('FORMS.SUBLETTING_APPLICATION.PROFESSION')"
          hide-details
          solo
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="localFormData.MOVE_PLANNED"
          v-mask="'##.##.####'"
          hint="DD.MM.YYYY"
          :rules="[rules.date]"
          :label="$t('FORMS.SUBLETTING_APPLICATION.MOVE_PLANNED')"
          hide-details
          solo
          :append-icon="errors.move_planned ? 'mdi-alert-circle-outline' : null"
          @update:error="errors = { ...errors, move_planned: $event }"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- <v-row dense class="text-center mt-3">
      <v-col cols="12">
        <p>{{ $t("FORMS.SUBLETTING_APPLICATION.ADD_SIGNED_CONTRACT") }}</p>
        <p>
          <v-btn class="font-weight-regular text-none _box-shadow" small @click="takePicture">
            <v-icon left>mdi-camera-outline</v-icon>
            {{ $t("FORMS.ADD_DOCUMENT") }}
          </v-btn>
        </p>

        <p v-if="imageElement.src">
          <img :src="`data:image/jpeg;base64, ${imageElement.src}`" width="100%" />
        </p>
      </v-col>
    </v-row> -->

    <v-row dense text-center>
      <v-col cols="12">
        <slot name="button" :valid="valid" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <slot name="description" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { Camera, CameraResultType } from "@capacitor/camera";

import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";

export default {
  name: "SublettingApplicationForm",

  directives: {
    mask
  },

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    localFormData: null,

    imageElement: {
      src: ""
    },

    valid: true,
    errors: {},

    rules: {
      required: Rules.required,
      birthdate: Rules.birthdate,
      date: Rules.date
    }
  }),

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    async takePicture() {
      const image = await Camera.getPhoto({
        width: 400,
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const imageUrl = image.base64String;
      // Can be set to the src of an image now
      this.imageElement.src = imageUrl;
      this.localFormData.picture = imageUrl;
    },

    setFormData() {
      this.$emit("input", this.localFormData);
    }
  }
};
</script>
